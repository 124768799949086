@use "sass:map";
@use "../config/variables" as variables;
@use "../helpers/mixin-text-contrast" as mixin;

$input-map-sizes: (
  sm: (
    font-size: map.get(variables.$typography-map-sizes, body),
  ),
  md: (
    font-size: map.get(variables.$typography-map-sizes, body),
  ),
  lg: (
    padding: 0 0.5rem,
    width: 100%,
    font-size: map.get(variables.$typography-map-sizes, body),
  ),
);

.user-input {
  align-items: center;
  border-radius: 8px;
  border: 2px solid variables.$color-border-main;
  display: flex;
  gap: 8px;
  justify-content: center;
  line-height: 2em; // em makes it relative to the current font size, not the root
  transition: all 200ms linear;
  margin-bottom: -5px;

  // Sizes
  @each $size, $properties in $input-map-sizes {
    &-#{$size} {
      padding: map.get($properties, padding);
      font-size: map.get($properties, font-size);
      width: map.get($properties, width);
    }
  }

  // Colors
  @each $theme, $border-colors in variables.$color-map-borders {
    &-#{$theme} {
      border-color: map.get($border-colors, main);
      @include mixin.text-contrast(map.get($border-colors, main));

      &:not([disabled]) {
        &:hover {
          border-color: map.get($border-colors, dark);
        }

        &:active {
          border-color: map.get($border-colors, light);
        }

        &:focus {
          border-color: map.get($border-colors, dark);

          &:hover {
            border-color: map.get($border-colors, dark);
          }

          &:active {
            border-color: map.get($border-colors, dark);
          }
        }
      }
    }
  }

  // Disabled
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  // Focus
  &:focus:not([disabled]) {
    box-shadow: inset white 0px 0px 0px 2px;
    outline: none;
  }
}
