@use "../config/variables" as variables;

:root {
  color: variables.$color-text-main;
  font-family: "Granary", sans-serif;
  font-size: variables.$typography-size-root;
  font-style: normal;
}

// Headings
@each $heading, $size in variables.$typography-map-headings {
  #{$heading} {
    font-family: "Granary", sans-serif;
    font-size: $size;
    font-style: normal;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

// Body
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

// Weights
@each $weight, $value in variables.$typography-map-weights {
  .text-#{$weight} {
    font-weight: $value;
  }
}

// Sizes
@each $size, $value in variables.$typography-map-sizes {
  .text-#{$size} {
    font-size: $value;
  }
}

// Styles
strong {
  @extend .text-bold;
}

small {
  @extend .text-sm;
}
