@use "../config/variables" as variables;

.display-visible {
  display: inherit !important;
}

.display-hidden {
  display: none !important;
}

@each $name, $size in variables.$breakpoint-map {
  @media (min-width: #{$size}) {
    .display-#{$name}-visible {
      display: inherit !important;
    }

    .display-#{$name}-hidden {
      display: none !important;
    }
  }
}
