@use "sass:map";
@use "../config/variables" as variables;
@use "../helpers/mixin-text-contrast" as mixin;

$color-map-callouts: map.merge(
  (
    default: variables.$color-map-backgrounds,
  ),
  variables.$color-map-themes
);

.callout {
  padding: 1rem;
  border-radius: 0.25rem;

  // Colors
  @each $theme, $theme-colors in $color-map-callouts {
    &-#{$theme} {
      background-color: map.get($theme-colors, light);
      @include mixin.text-contrast(map.get($theme-colors, light));
    }
  }
}
