@use "../../../styles/config/variables" as variables;

.content-layout-column {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.content-layout-column-sm-row-lg {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (min-width: variables.$breakpoint-lg) {
    flex-direction: row;
  }
}
