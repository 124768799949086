@use "sass:map";
@use "../config/variables" as variables;
@use "../helpers/mixin-text-contrast" as mixin;

$button-map-sizes: (
  sm: (
    padding: 0 1rem,
    font-size: map.get(variables.$typography-map-sizes, sm),
  ),
  md: (
    padding: 0 1.5rem,
    font-size: map.get(variables.$typography-map-sizes, body),
  ),
  lg: (
    padding: 0 2rem,
    font-size: map.get(variables.$typography-map-sizes, h4),
  ),
);

.button {
  align-items: center;
  border-radius: 40px;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: center;
  line-height: 2em; // em makes it relative to the current font size, not the root
  transition: all 200ms linear;

  // Sizes
  @each $size, $properties in $button-map-sizes {
    &-#{$size} {
      padding: map.get($properties, padding);
      font-size: map.get($properties, font-size);
    }
  }

  // Colors
  @each $theme, $theme-colors in variables.$color-map-themes {
    &-#{$theme} {
      background-color: map.get($theme-colors, main);
      @include mixin.text-contrast(map.get($theme-colors, main));

      &:not([disabled]) {
        &:hover {
          background-color: map.get($theme-colors, dark);
        }

        &:active {
          background-color: map.get($theme-colors, light);
        }

        &:focus {
          border-color: map.get($theme-colors, main);

          &:hover {
            border-color: map.get($theme-colors, dark);
          }

          &:active {
            border-color: map.get($theme-colors, light);
          }
        }
      }
    }
  }

  // Disabled
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  // Focus
  &:focus:not([disabled]) {
    box-shadow: inset white 0px 0px 0px 2px;
    outline: none;
  }
}
