@use "./styles/styles.scss" as aptem-variables with (
  $color-primary-main: #0080a6,
  $color-primary-light: #a5d2df,
  $color-primary-dark: #005779
);

@use "./assets/scss/helpers/action-alignments";
@use "./assets/scss/helpers/content-layouts";
@use "./assets/scss/overrides/svg-icon";

@use "styles/config/variables" as variables;

h1 {
  font-weight: variables.$typography-weight-bold;
  font-size: variables.$typography-size-h1;
  color: variables.$color-secondary-main;
}

h2 {
  font-weight: variables.$typography-weight-bold;
  font-size: variables.$typography-size-h2;
  color: variables.$color-secondary-main;
}

h4 {
  font-weight: variables.$typography-weight-bold;
  font-size: variables.$typography-size-h4;
  color: variables.$color-secondary-main;
}