@use "../config/variables" as variables;

@mixin text-contrast($n) {
  $color-brightness: round(
    (red($n) * 299) + (green($n) * 587) + calc((blue($n) * 114) / 1000)
  );
  $light-color: round(
    (red(#ffffff) * 299) + (green(#ffffff) * 587) +
      calc((blue(#ffffff) * 114) / 1000)
  );

  @if abs($color-brightness) < (calc($light-color/2)) {
    color: variables.$color-flats-white;
  } @else {
    color: variables.$color-text-main;
  }
}
