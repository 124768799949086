@use "sass:map";

/**
 * Typography
 */

// Sizes
$typography-size-root: 16px;
$typography-size-h1: 1.75rem;
$typography-size-h2: 1.5rem;
$typography-size-h3: 1.25rem;
$typography-size-h4: 1.125rem;
$typography-size-body: 1rem;
$typography-size-sm: 0.875rem;
$typography-size-xs: 0.75rem;

// Weights
$typography-weight-light: 300;
$typography-weight-regular: 400;
$typography-weight-medium: 500;
$typography-weight-bold: 700;

// Maps
$typography-map-headings: (
  h1: $typography-size-h1,
  h2: $typography-size-h2,
  h3: $typography-size-h3,
  h4: $typography-size-h4,
);

$typography-map-sizes: map.merge(
  $typography-map-headings,
  (
    body: $typography-size-body,
    sm: $typography-size-sm,
    xs: $typography-size-xs,
  )
);

$typography-map-weights: (
  light: $typography-weight-light,
  regular: $typography-weight-regular,
  medium: $typography-weight-medium,
  bold: $typography-weight-bold,
);

/**
 * Colors
 */

// Theme
$color-primary-main: #0080a6 !default;
$color-primary-light: #c5d1f6 !default;
$color-primary-dark: #0a2063 !default;
$color-secondary-main: #03304d !default;
$color-secondary-light: #9aacb8 !default;
$color-secondary-dark: #011623 !default;
$color-flats-white: #ffffff !default;
$color-flats-black: #222222 !default;
$color-brand-primary: #0184fd !default;
$color-brand-secondary: #01dbc6 !default;

// Status
$color-success-main: #0c7a3a !default;
$color-success-light: #9ecab0 !default;
$color-success-dark: #074923 !default;
$color-warning-main: #c63500 !default;
$color-warning-light: #f7c5b8 !default;
$color-warning-dark: #5e2c1f !default;
$color-danger-main: #c9025f !default;
$color-danger-light: #e99abf !default;
$color-danger-dark: #500126 !default;
$color-info-main: #1746dc !default;
$color-info-light: #e8edfc !default;
$color-info-dark: #0e2a84 !default;
$color-note-main: #f3ca40 !default;
$color-note-light: #fdf4d9 !default;
$color-note-dark: #61511a !default;

// Utility
$color-border-main: #ced4e2 !default;
$color-border-dark: #03304d !default;
$color-background-main: #ffffff !default;
$color-background-light: #f3f5f9 !default;
$color-background-dark: #c0cbd3 !default;
$color-background-danger: #fae6ef !default;
$color-highlight-main: #e8edfc !default;
$color-text-main: #03304d !default;
$color-text-light: #4f6e82 !default;
$color-text-dark: #011623 !default;

$color-platinum-main: #525F7F !default;

// Maps
$color-map-all: (
  "primary-main": $color-primary-main,
  "primary-light": $color-primary-light,
  "primary-dark": $color-primary-dark,
  "secondary-main": $color-secondary-main,
  "secondary-light": $color-secondary-light,
  "secondary-dark": $color-secondary-dark,
  "flats-white": $color-flats-white,
  "flats-black": $color-flats-black,
  "brand-primary": $color-brand-primary,
  "brand-secondary": $color-brand-secondary,
  "success-main": $color-success-main,
  "success-light": $color-success-light,
  "success-dark": $color-success-dark,
  "warning-main": $color-warning-main,
  "warning-light": $color-warning-light,
  "warning-dark": $color-warning-dark,
  "danger-main": $color-danger-main,
  "danger-light": $color-danger-light,
  "danger-dark": $color-danger-dark,
  "info-main": $color-info-main,
  "info-light": $color-info-light,
  "info-dark": $color-info-dark,
  "note-main": $color-note-main,
  "note-light": $color-note-light,
  "note-dark": $color-note-dark,
  "border-main": $color-border-main,
  "border-dark": $color-border-dark,
  "background-main": $color-background-main,
  "background-light": $color-background-light,
  "background-dark": $color-background-dark,
  "highlight-main": $color-highlight-main,
  "text-main": $color-text-main,
  "text-light": $color-text-light,
  "text-dark": $color-text-dark,
);

$color-map-themes: (
  primary: (
    main: $color-primary-main,
    light: $color-primary-light,
    dark: $color-primary-dark,
  ),
  secondary: (
    main: $color-secondary-main,
    light: $color-secondary-light,
    dark: $color-secondary-dark,
  ),
  success: (
    main: $color-success-main,
    light: $color-success-light,
    dark: $color-success-dark,
  ),
  warning: (
    main: $color-warning-main,
    light: $color-warning-light,
    dark: $color-warning-dark,
  ),
  danger: (
    main: $color-danger-main,
    light: $color-danger-light,
    dark: $color-danger-dark,
  ),
  info: (
    main: $color-info-main,
    light: $color-info-light,
    dark: $color-info-dark,
  ),
  note: (
    main: $color-note-main,
    light: $color-note-light,
    dark: $color-note-dark,
  ),
);

$color-map-backgrounds: (
  main: $color-background-main,
  light: $color-background-light,
  dark: $color-background-dark,
);

$color-map-borders: (
  primary: (
    main: $color-border-main,
    dark: $color-border-dark,
  )
);

/**
 * Breakpoints
 */

$breakpoint-sm: 0px;
$breakpoint-md: 600px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1800px;

$breakpoint-map: (
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
);

/**
 * Utils
 */

// Sides
$utils-map-sides: (
  t: top,
  r: right,
  b: bottom,
  l: left,
);
