@use "../config/variables" as variables;

@each $name, $hex in variables.$color-map-all {
  .b-#{$name} {
    border: 1px solid $hex;
  }

  @each $short, $long in variables.$utils-map-sides {
    .b-#{$short}-#{$name} {
      border-#{$long}: 1px solid $hex;
    }
  }
}
