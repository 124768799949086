@use "../config/variables" as variables;

$spacing-map: (
  0: 0,
  1: 0.5rem,
  2: 1rem,
  3: 1.5rem,
  4: 2rem,
  5: 2.5rem,
);

@each $index, $value in $spacing-map {
  @each $short, $long in variables.$utils-map-sides {
    .m-#{$short}-#{$index} {
      margin-#{$long}: $value;
    }

    .p-#{$short}-#{$index} {
      padding-#{$long}: $value;
    }
  }

  .m-#{$index} {
    margin: $value;
  }

  .p-#{$index} {
    padding: $value;
  }

  .m-x-#{$index} {
    @extend .m-r-#{$index};
    @extend .m-l-#{$index};
  }

  .m-y-#{$index} {
    @extend .m-t-#{$index};
    @extend .m-b-#{$index};
  }

  .p-x-#{$index} {
    @extend .p-r-#{$index};
    @extend .p-l-#{$index};
  }

  .p-y-#{$index} {
    @extend .p-t-#{$index};
    @extend .p-b-#{$index};
  }
}
