@use "../../../styles/config/variables" as variables;

.action-button-block-sm-end-md {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: variables.$breakpoint-md) {
    flex-direction: row;
    justify-content: end;
  }
}

.action-button-block-sm-center-md {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: variables.$breakpoint-md) {
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: variables.$breakpoint-lg) {
    flex-direction: row;
    justify-content: center;
  }
}
