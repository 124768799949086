@use "../config/variables" as variables;

$loading-map-colors: (
  primary: variables.$color-primary-main,
  secondary: variables.$color-secondary-main,
  light: variables.$color-flats-white,
);

$loading-map-sizes: (
  sm: 1rem,
  md: 2rem,
  lg: 4rem,
);

.loading-spinner {
  animation: rotation 1s linear infinite;
  border-radius: 50%;
  display: inline-block;

  // Sizes
  @each $size, $value in $loading-map-sizes {
    &-#{$size} {
      height: $value;
      width: $value;
      font-size: $value;
    }
  }

  // Colors
  @each $theme, $value in $loading-map-colors {
    &-#{$theme} {
      border: 0.1875em solid $value;
      border-bottom-color: transparent;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
