// Granary
@font-face {
  font-family: "Granary";
  src:
    url("../../assets/fonts/granary-light.woff2") format("woff2"),
    url("../../assets/fonts/granary-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Granary";
  src:
    url("../../assets/fonts/granary-regular.woff2") format("woff2"),
    url("../../assets/fonts/granary-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Granary";
  src:
    url("../../assets/fonts/granary-medium.woff2") format("woff2"),
    url("../../assets/fonts/granary-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Granary";
  src:
    url("../../assets/fonts/granary-bold.woff2") format("woff2"),
    url("../../assets/fonts/granary-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}